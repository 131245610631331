import React from "react";
import { graphql } from "gatsby";
import { Navigation } from "../components/global/nav";
import { Footer } from "../components/global/footer";
import { FaCompass, FaMobileAlt as Phone } from "react-icons/fa"
import "../styles.css"
import Breadcrumbs from "../components/global/breadcrumbs";
import SEO from "../components/global/seo";

const ManuPageTemplate = props => {
   const page = props.data.contentfulManufacturerPage;
   const manu = props.data.contentfulManufacturerLanding;
   const related_articles = props.data.allContentfulManufacturerPage.edges;
   
   // Variables
   const slug = page.slug;
   const title = page.title;
   const title_split = title.split("-");
   const metadescription = page.metaDescription;
   const articledescription = page.articleDescription.internal.content;
   const manufacturer = page.manufacturer;
   const content = page.content.internal.content;
   const string = page.navigation.internal.content;
   const json = JSON.parse(string);
   const array = Object.entries(json);

   const hq = manu.headquarters;
   const founded = manu.yearFounded;
   const ceo = manu.ceo;
   const image = manu.companyLogo.file.url;

   const printInternalNavigation = () => {
      let jsx = [];

      for (let i = 0; i < array.length; i++) {
         const el = array[i];
         const keyVAl = Object.entries(el[1])
         
         jsx.push(
            <a key={i} href={`#${keyVAl[0][1]}`}><li>{keyVAl[0][0]}</li></a>
         )
      }

      return jsx;
   }

   const ArtDesc = () => {
      return {__html: articledescription}
   }

   const bodyContent = () => {
      return {__html: content}
   }

   const printRelatedArticles = () => {
      let jsx = [];

      for (let i = 0; i < related_articles.length; i++) {
         const el = related_articles[i];
         
         jsx.push(
            <a href={`/${el.node.slug}`}><li>{el.node.title}</li></a>
         )
      }

      return jsx;
   }

   return (
      <React.Fragment>
         <SEO
            title={title}
            slug={slug}
            metadescription={metadescription}
            image=""
            titleTemplate="%s"
         />
         <Navigation/>
            <div className="manufacturer-title-container">
               <div className="manufacturer-title-inner-container body-inner-width">
                  <div className="manufacturer-title-text">
                     <h1>{title_split[0]}
                     <br/><span id="title_part2">{title_split[1]}</span></h1>

                     <div className="at-a-glance-container">
                        <p>Company type: <span>Manufacturer</span></p>
                        <p>HQ: <span>{hq}</span></p>
                        <p>Founded: <span>{founded}</span></p>
                        <p>CEO: <span>{ceo}</span></p>
                     </div>

                     <span dangerouslySetInnerHTML={ArtDesc()}></span>

                     <button className="large-button green-button repair disabled">Book a repair</button>
                  </div>
                  <div className="manufacturer-title-image">
                    <img 
                     src={`https:${image}`} 
                     alt={`${manufacturer} Logo`}
                     title={`${manufacturer} are supported by Smart Plan`}
                  />
                 </div>
               </div>
            </div>

            <div className="standard-container">
               <div className="standard-inner-container body-inner-width">
                  <div className="standard-content-container">
                     {/* CONTENT */}

                     <div className="content-column">
                        <Breadcrumbs
                           location={slug}
                        />

                        {/* BODY */}
                        <div className="content-body">
                           <span dangerouslySetInnerHTML={bodyContent()}/>
                        </div>
                     </div>

                     {/* SIDBAR */}
                     <div className="navigation-column">
                        <div className="navigation-entries-container">
                           <div className="article-navigation-ol-container">
                              <div className="article-navigation-ol-header">
                                 <FaCompass/>&nbsp;&nbsp;Navigation
                              </div>

                              <ol className="article-navigation-ol">
                                 {printInternalNavigation()}
                              </ol>
                           </div>

                           {
                              array.length >= 4 ? (
                                 <button className="plain-text-btn nav-to-top" onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>Go to the top</button>
                              ) : null
                           }

                           <ul className="related-articles">
                              <h4>Related articles</h4>
                              {printRelatedArticles()}
                           </ul>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>

            <div className="end-article-cta-container">
               <div className="standard-inner-container body-inner-width">
                  <h3 className="end-article-cta-header">Still need help with your {manufacturer} boiler?</h3>
                  <p>Call Smart Plan and speak to our expert engineering team free of charge.</p>

                  <a href="tel:+443337726247" className="large-button green-button end-article-cta-button">0333&nbsp;772&nbsp;6247 <Phone/></a>
               </div>
            </div>
            
         <Footer/>
      </React.Fragment>
   )
}

export default ManuPageTemplate

export const pageQuery = graphql`
   query ContentfulDetails( $moduleRegex: String!, $slug: String!) {
      contentfulManufacturerPage( slug: { eq: $slug}) {
         title
         slug
         articleDescription {
            internal {
               content
            }
         }
         content {
            internal {
               content
            }
         }
         navigation {
            internal {
               content
            }
         }
         manufacturer
      }

      contentfulManufacturerLanding( slug: { regex: $moduleRegex}) {
         yearFounded
         ceo
         headquarters
         companyLogo {
            file {
               url
            }
         }
      }

      allContentfulManufacturerPage( filter: { slug: { regex: $moduleRegex }}) {
         edges {
            node {
               slug
               title
            }
         }
      }
   }

`